import LegendaryHero_Hazmat from "../assets/legendary_hazmat";
import LegendaryHero_Space from "../assets/legendary_space";
import LegendaryHero_Scuba from "../assets/legendary_scuba";
import LegendaryHero_Surfer from "../assets/legendary_surfer";
import SkullOfTygerHero from "../assets/skullOfTyger";
import Slider from "../components/Slider";
import { FaExclamation } from "react-icons/fa";
import CollectionDetails from "../components/CollectionDetails";
// import PropTypes from "prop-types";
function LegendaryFolkHeroes() {
  return (
    <>
      <div className="page-background-solid">
        <div className="page-container">
          <CollectionDetails />
          <div className="page-title page-title-reverse">
            Legendary Folk Heroes
          </div>
        </div>
      </div>
      <div className="page-container">
        <div className="section-tab section-full-width">
          <div className="section-tab-header">A limited collection of the rarest mintable renditions of Johnny
            spreading peace, love, happiness & positivity.</div>
          <p>
            There are <b>27</b> in total and are tied to unique traits, badges
            and more.
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: ".65em",
            }}
          >
            <div className="section-tab-callout">
              <FaExclamation />
            </div>
            <div>
              Minting a Legendary Johnny Folk Hero awards the{" "}
              <b>Skull of Diamond NFT</b>.
            </div>
          </div>
        </div>
      </div>

      <div className="page-container">
        <div className="legendary-page-slider-group">
          <div className="page-card-container slider-container">
            <div className="page-card-header card-header-white">Hazmat</div>
            <div className="page-card-content card-content-white card-slider">
              <Slider
                imageSet={LegendaryHero_Hazmat}
                slidesPerView={4}
                thumbRows={0}
                spacing={5}
              />
            </div>
          </div>
          <div className="page-card-container slider-container">
            <div className="page-card-header card-header-white">Astronaut</div>
            <div className="page-card-content card-content-white card-slider">
              <Slider
                imageSet={LegendaryHero_Space}
                slidesPerView={4}
                thumbRows={0}
                spacing={5}
              />
            </div>
          </div>
          <div className="page-card-container slider-container">
            <div className="page-card-header card-header-white">
              Scuba Diver
            </div>
            <div className="page-card-content card-content-white card-slider">
              <Slider
                imageSet={LegendaryHero_Scuba}
                slidesPerView={4}
                thumbRows={0}
                spacing={5}
              />
            </div>
          </div>
          <div className="page-card-container slider-container">
            <div className="page-card-header card-header-white">Surfer</div>
            <div className="page-card-content card-content-white card-slider">
              <Slider
                imageSet={LegendaryHero_Surfer}
                slidesPerView={4}
                thumbRows={0}
                spacing={5}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="page-background-solid" style={{ marginTop: "1em" }}>
        <div className="page-container">
          <div className="page-title page-title-reverse">The Skull of Tyger Folk Heroes</div>

        </div>
      </div>
      <div className="page-container">
        <div className="section-tab section-full-width">
          These     make up <b>11 of the 27</b> Legendaries included in the mint.
          <p>
            This exclusive collection features Johnny collecting the Lost Gem
            Skulls of Tyger on his journey to spread peace, love, happiness &
            positivity.
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: ".65em",
            }}
          >
          <div className="section-tab-callout">
            <FaExclamation />
          </div>
          <div>
            Minting a Legendary Johnny Folk Hero awards:
            <p>
              <b>Skull of Diamond NFT</b> and...
            </p>
            <b>Skull of Tyger Mint</b> {"("}when the collection releases{")"}
            </div></div>
        </div></div>
      <div className="page-container">
        <div className="page-card-container slider-container-large">
          <div className="page-card-header card-header-white">
            Skull of Tyger
          </div>
          <div className="page-card-content card-content-white card-slider">
            <Slider
              imageSet={SkullOfTygerHero}
              slidesPerView={11}
              thumbRows={0}
              spacing={0}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default LegendaryFolkHeroes;
