import { web3 } from "@project-serum/anchor";
import React, { createContext, useContext, useState } from "react";

type Props = {
  children: React.ReactNode;
};

const MintItems: {
  isUserMinting: boolean;
  mintAddress: web3.PublicKey | undefined;
  setIsUserMinting: React.Dispatch<React.SetStateAction<boolean>>;
  setMintAddress: React.Dispatch<
    React.SetStateAction<web3.PublicKey | undefined>
  >;
} = {
  isUserMinting: false,
  mintAddress: undefined,
  setIsUserMinting: () => {},
  setMintAddress: () => {},
};

export const MintData = createContext(MintItems);

export const MintDataProvider = ({ children }: Props) => {
  const [isUserMinting, setIsUserMinting] = useState(false);
  const [mintAddress, setMintAddress] = useState<web3.PublicKey | undefined>();

  // const mintAddress = web3.Keypair.generate().publicKey;

  return (
    <MintData.Provider
      value={{ isUserMinting, mintAddress, setIsUserMinting, setMintAddress }}
    >
      {children}
    </MintData.Provider>
  );
};

export function useMintData() {
  return useContext(MintData);
}
