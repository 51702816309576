import Countdown from "react-countdown";
import React from "react";
import { styled } from "@mui/system";

const DoneContainer = styled("span")`
  // width: 55px;
  // height: 24px;
  // display: flex;
  // align-items: center;
  // margin-top: 3px;
  padding: 0px 8px;
  padding-top: 2px;
  color: #423737;
  border-radius: 3px;
  background-color: #f4eab1 !important;
  font-size: 14px !important;
  font-weight: 700;
  text-transform: capitalize !important;
  text-align: center;
  background: "#f4eab1";
`;

interface MintCountdownProps {
  date: Date | undefined;
  style?: React.CSSProperties;
  status?: string;
  onComplete?: () => void;
}

interface MintCountdownRender {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
}

export const MintCountdown: React.FC<MintCountdownProps> = ({
  date,
  status,
  onComplete,
}) => {
  const renderCountdown = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: MintCountdownRender) => {
    hours += days * 24;
    if (completed) {
      return status ? <DoneContainer>{status}</DoneContainer> : null;
    } else {
      return (
        <>
          <div className="countdown-container">
            <div style={{ fontWeight: "600" }}>Countdown to Mint:</div>
            <div>
              {hours < 10 ? `0${hours}` : hours} hrs{" "}
              {minutes < 10 ? `0${minutes}` : minutes} mins{" "}
              {seconds < 10 ? `0${seconds}` : seconds} secs
            </div>
          </div>
        </>
      );
    }
  };

  if (date) {
    return (
      <Countdown
        date={date}
        onComplete={onComplete}
        renderer={renderCountdown}
      />
    );
  } else {
    return null;
  }
};
