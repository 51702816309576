import CollectionDetails from "../components/CollectionDetails";
import heroNFTImage from "../assets/NFT_callout_image.jpg";
import seedGrp from "../assets/tellingTheTale/seedsGrp.png";
import sceneGrp from "../assets/tellingTheTale/scenesGrp.png";
import hatColorGrp from "../assets/tellingTheTale/hatColorGrp.png";
import hatStyleGrp from "../assets/tellingTheTale/hatStyleGrp.png";
import weatherGrp from "../assets/tellingTheTale/weatherGrp.png";
import timeOfDayGrp from "../assets/tellingTheTale/timeOfDayGrp.png";
import seasonGrp from "../assets/tellingTheTale/seasonGrp.png";
import birdGrp from "../assets/tellingTheTale/birdGrp.png";
import dogGrp from "../assets/tellingTheTale/dogGrp.png";

function TellingTheTale() {
  return (
    <>
      <div className="page-background-solid">
        <div className="page-container">
          <CollectionDetails />
          <div className="page-title page-title-reverse">Telling The Tale</div>      </div>
      </div>
      <div className="page-container">
        <div className="section-tab section-full-width">
          <div className="section-tab-header">Using art and metadata - every outcome will tell a tale true to your
            Johnny Folk Hero!</div>
          <p>
            Each piece of art has over 50 potential metadata traits that will
            determine the outcome of the art.
          </p>
          <p>
            9 of those traits will alter the story that is told by each Johnny
            Folk Hero NFT
          </p>
        </div></div>
      <div className="page-container">
        <div className="page-card-container" style={{ marginTop: "1em" }}>
          <div className="page-card-header card-header-white">
            How this works
          </div>
          <div className="page-card-content card-content-white">
            <p className="card-content-header">
              Starting with Johnny&apos;s Story Template
            </p>
            <p>
              On the brink of chaos, a vile infection of negativity, greed,
              hatred and fear has corrupted the spirit of humanity. With a pep
              in his step and an eager heart, Johnny Folk Hero casts out his
              arm, spreading seeds of <b className="code">[seed]</b>
              <b className="code">[scene]</b>. Wearing his{" "}
              <b className="code">[hat color]</b>{" "}
              <b className="code">[hat type]</b> hat on a{" "}
              <b className="code">[weather]</b>
              <b className="code">[time-of-day]</b> during{" "}
              <b className="code">[season]</b>.
              <b className="code">[bird-on-shoulder]</b>
              <b className="code">[dog]</b> His hope is that each seed will
              grow, entangling itself into the hearts and minds of those in
              need; creating a life of beauty and serenity.
            </p>
            <p className="card-content-header">
              Traits are chosen and fill in the story
              <div className="tale-page-trait-container">
                <div className="tale-page-trait-group">
                  <div className="tale-page-trait-title">Seeds</div>
                  <div>
                    <img
                      className="tale-page-trait-image"
                      src={seedGrp}
                      alt="seed traits"
                    />
                  </div>
                  <div className="tale-page-trait-text">love</div>
                </div>

                <div className="tale-page-trait-group">
                  <div className="tale-page-trait-title">Scene</div>
                  <div>
                    <img
                      className="tale-page-trait-image"
                      src={sceneGrp}
                      alt="scene traits"
                    />
                  </div>
                  <div className="tale-page-trait-text">
                    on the country roads
                  </div>
                </div>

                <div className="tale-page-trait-group">
                  <div className="tale-page-trait-title">Hat Color</div>
                  <div>
                    <img
                      className="tale-page-trait-image"
                      src={hatColorGrp}
                      alt="hat color traits"
                    />
                  </div>
                  <div className="tale-page-trait-text">grey</div>
                </div>

                <div className="tale-page-trait-group">
                  <div className="tale-page-trait-title">Hat Style</div>
                  <div>
                    <img
                      className="tale-page-trait-image"
                      src={hatStyleGrp}
                      alt="hat style traits"
                    />
                  </div>
                  <div className="tale-page-trait-text">bowler</div>
                </div>

                <div className="tale-page-trait-group">
                  <div className="tale-page-trait-title">Weather</div>
                  <div>
                    <img
                      className="tale-page-trait-image"
                      src={weatherGrp}
                      alt="weather traits"
                    />
                  </div>
                  <div className="tale-page-trait-text">hot</div>
                </div>

                <div className="tale-page-trait-group">
                  <div className="tale-page-trait-title">Time of Day</div>
                  <div>
                    <img
                      className="tale-page-trait-image"
                      src={timeOfDayGrp}
                      alt="time of day traits"
                    />
                  </div>
                  <div className="tale-page-trait-text">mid-day</div>
                </div>

                <div className="tale-page-trait-group">
                  <div className="tale-page-trait-title">Season</div>
                  <div>
                    <img
                      className="tale-page-trait-image"
                      src={seasonGrp}
                      alt="season traits"
                    />
                  </div>
                  <div className="tale-page-trait-text">a cold Spring</div>
                </div>

                <div className="tale-page-trait-group">
                  <div className="tale-page-trait-title">Bird on Shoulder</div>
                  <div>
                    <img
                      className="tale-page-trait-image"
                      src={birdGrp}
                      alt="bird traits"
                    />
                  </div>
                  <div className="tale-page-trait-text">
                    A <b>House Finch</b> rests on his shoulder to set his
                    worries at ease.
                  </div>
                </div>

                <div className="tale-page-trait-group">
                  <div className="tale-page-trait-title">Dog</div>
                  <div>
                    <img
                      className="tale-page-trait-image"
                      src={dogGrp}
                      alt="dog traits"
                    />
                  </div>
                  <div className="tale-page-trait-text">
                    Full of spirit, his faithful companion,<b> Huck</b> walks
                    with him in stride.
                  </div>
                </div>
              </div>
            </p>
          </div>
        </div>
      </div>
      <div className="page-background" style={{ marginTop: "1rem" }}>
        <div className="page-container">
          <p className="page-title">The NFT is Generated</p>
          <div className="tale-page-generated-combo">
            <div className="tale-page-story-text">
              <p style={{ lineHeight: "1.5em" }}>
                On the brink of chaos, a vile infection of negativity, greed,
                hatred and fear has corrupted the spirit of humanity. With a pep
                in his step and an eager heart, Johnny Folk Hero casts out his
                arm, spreading seeds of <b>love</b> on the <b>country roads</b>.
                Wearing his <b>grey bowler</b> hat on a <b>hot mid-day</b>{" "}
                during <b>a cold Spring</b>.
                <b>
                  <span className="tale-page-medium-text"> A </span>
                  House Finch{" "}
                  <span className="tale-page-medium-text">
                    rests on his shoulder to set his worries at ease. Full of
                    spirit, his faithful companion,{" "}
                  </span>
                  Huck,{" "}
                  <span className="tale-page-medium-text">
                    walks with him in stride.
                  </span>
                </b>{" "}
                His hope is that each seed will grow, entangling itself into the
                hearts and minds of those in need; creating a life of beauty and
                serenity.
              </p>
            </div>
            <div style={{ padding: "2em" }}>
              <img
                className="tale-page-nft-image"
                src={heroNFTImage}
                alt="NFT Example"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TellingTheTale;
