import { useMintData } from "../context/MintData";
import Lottie from "react-lottie-player";
import animationData from "../assets/icons/johnnyWalker_lottie.json";
import { useNavigate } from "react-router-dom";

function Minting() {
  const { isUserMinting } = useMintData();
  const navigate = useNavigate();

  return (

    <>
      <div
        className="page-container"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {isUserMinting === false ? (<>
          <div
            className="page-title"
            style={{
              color: "#faf9f6",
              textAlign: "center",
              lineHeight: "1.2em",
            }}
          >
            Johnny might have stumbled.<br />Connect your wallet to mint...
            <p><button className="page-button" onClick={() => { navigate("/") }}>Home</button></p>
          </div>

        </>) : (
          <>
              <div className="page-title" style={{ color: "#faf9f6" }}>
                Minting...
              </div>
              <Lottie
                loop
                animationData={animationData}
                play
                style={{ width: "200px", height: "200px" }}
              />
          </>
        )}
      </div>
    </>
  );
}

export default Minting;
