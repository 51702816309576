// import { PublicKey } from "@solana/web3.js";

import { useEffect, useState } from "react";

import { useMintData } from "../context/MintData";
import traitBadges from "../assets/badges";
import NftBadge from "../components/NftBadge";
import { useNavigate } from "react-router-dom";
import CollectionDetails from "../components/CollectionDetails";
import explorerIcon from "../assets/icons/explorer.png";
import solanaFmIcon from "../assets/icons/solanaFM.png";
import solscanIcon from "../assets/icons/solscan.png";
import BottomMint from "../components/BottomMint";
import { Metaplex } from "@metaplex-foundation/js";
import PropTypes from "prop-types";

function MintResult({ connection }) {
  const { mintAddress } = useMintData();
  const metaplex = Metaplex.make(connection)

  const [newNft, setNewNft] = useState(null);
  const [totalTraits, setTotalTraits] = useState(0);
  const navigate = useNavigate();

  // const testingNft = new PublicKey(
  //   "Fz4Z51ZmyetynKjFiwXQyfjFc367xMs29aUndJHSaRhS"
  // );
  console.log(metaplex)

  useEffect(() => {
    if (mintAddress) {
      const getNewNft = async () => {
        const nft = await metaplex?.nfts().findByMint({ mintAddress });

        if (nft) {
          // console.log("nft object: ", nft);
          // console.log(nft.json.image);
          // console.log("story: ", nft.json.description);
          setNewNft(nft);
          setTotalTraits(nft.json.attributes.length);
        }
      };
      getNewNft();
    }
  }, [mintAddress]);

  let foundSpecialTraits = false;
  let potHatColor = ""
  if (newNft) {
    newNft.json.attributes.map((trait) => {
      traitBadges.map((badge) => {
        if (badge.imgName === trait.trait_type) {
          foundSpecialTraits = true;
        }
        if (badge.imgName === trait.value) {
          foundSpecialTraits = true;
        }
        if (trait.trait_type === "Hat Color") {
          potHatColor = trait.value
        }
      });
    });
  }

  return (

    <>
      {newNft ? (
        <>
          <div className="page-background">
            <div className="page-container">
              <CollectionDetails isHomePage={true} />
              <div className="page-title">Your Story...</div>
              <div
                className="minted-generated-combo"
                style={{ minHeight: "40rem", marginBottom: "1rem" }}
              >
                <img
                  src={newNft.json.image}
                  alt="NFT Image"
                  className="tale-page-trait-image nft-image"
                />
                <div className="nft-data-container">
                  <div style={{ margin: "0 1em" }}>
                    <div className="page-card-container" >
                      <div className="page-card-header">{newNft.name}</div>

                      <div className="page-card-content">
                        <p>{newNft.json.description}</p>
                      </div>
                    </div>
                    <div className="section-tab add-shadow">
                      <div>
                        This Johnny Folk Hero has <b>{totalTraits}</b> traits!
                      </div>
                    </div>
                    {foundSpecialTraits === true && (
                      <>
                        <p className="nft-badge-header">Badges acquired:</p>
                        <div className="nft-badge-group">
                          {newNft.json.attributes.map((specialTrait) =>
                            traitBadges.map((badge, id) => {
                              if (
                                specialTrait.trait_type === badge.trait_type
                              ) {
                                  return (
                                    <NftBadge key={id} badge={badge} id={id} value={specialTrait.value} potHat={potHatColor} nftName={newNft.name} />
                                  );
                              } 
                            })
                          )}
                        </div>
                      </>
                    )}

                    <div className="mint-explorer-container">
                      <div className="mint-explorer-view">View NFT:</div>
                      <a
                        className="mint-explorer-group"
                        href={`https://solscan.io/token/${newNft.address.toBase58()}`}
                      >
                        <div>
                          <img
                            className="mint-explorer-icon"
                            src={solscanIcon}
                            alt="solscan"
                          />
                        </div>
                        <div className="mint-explorer-text">Solscan</div>
                      </a>
                      <a
                        className="mint-explorer-group"
                        href={`https://solana.fm/address/${newNft.address.toBase58()}`}
                      >
                        <div>
                          <img
                            className="mint-explorer-icon"
                            src={solanaFmIcon}
                            alt="solscan"
                          />
                        </div>
                        <div className="mint-explorer-text">Solana FM</div>
                      </a>
                      <a
                        className="mint-explorer-group"
                        href={`https://explorer.solana.com/address/${newNft.address.toBase58()}`}
                      >
                        <div>
                          <img
                            className="mint-explorer-icon"
                            src={explorerIcon}
                            alt="solscan"
                          />
                        </div>
                        <div className="mint-explorer-text">Explorer</div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mint-bottom"><BottomMint /></div>
            </div>
          </div>
        </>
      ) : (
          <>
            <div
              className="page-title"
              style={{
                color: "#faf9f6",
                textAlign: "center",
                lineHeight: "1.2em",
              }}
            >

              <p style={{ fontWeight: "200" }}>Check your wallet for your mint.
              </p>

              <p>
                <button
                  className="page-button page-button-alt"
                  onClick={() => {
                    navigate("/your-johnny");
                  }}
                >
                  View Your Johnny&apos;s
                </button>
              </p>
              {/* <p>
                <button
                  className="page-button"
                  onClick={() => {
                    navigate("/");
                  }}
                >Home Page
                </button>
              </p> */}
            </div>
          </>
      )}
    </>
  );
}
MintResult.propTypes = {
  connection: PropTypes.any,
};
export default MintResult;
