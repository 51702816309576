import { createTheme, ThemeProvider } from "@mui/material/styles";

import PropTypes from "prop-types";

const theme = createTheme({
  // root: {
  //   "&.Mui-disabled": {
  //     backgroundColor: "white",
  //   },
  // },

  components: {
    MuiDialogTitle: {
      styleOverrides: {
        root: { backgroundColor: "#423737 !important", color: "white" },
      },
    },

    MuiButton: {
      styleOverrides: {
        contained: { backgroundColor: "#f4eab1 " },
        text: {
          backgroundColor: "#312a2a !important",
          color: "#faf9f6 !important",
        },
        root: {
          "&.Mui-disabled": { color: "white !important", opacity: "75%" },
        },
        // disabled: { backgroundColor: "white" },
      },
    },
    MuiIconButton: {
      styleOverrides: { sizeLarge: { backgroundColor: "white !important" } },
    },
  },
});
// MODAL BUTTONS
// MuiButtonBase - root
// MuiButton - root
// MuiButton - text
// MuiButton - textPrimary
// MuiButton - sizeMedium
// MuiButton - textSizeMedium
// MuiButton - root
// MuiButton - text
// MuiButton - textPrimary
// MuiButton - sizeMedium
// MuiButton - textSizeMedium
// css - 1tlumaq - MuiButtonBase - root - MuiButton - root

// MuiButtonBase - root
// MuiIconButton - root
// MuiIconButton - sizeLarge
// css - 1t6gutj - MuiButtonBase - root - MuiIconButton - root

// CONNECT/MINT
// MuiButtonBase - root
// MuiButton - root
// MuiButton - contained
// MuiButton - containedPrimary
// MuiButton - sizeMedium
// MuiButton - containedSizeMedium
// MuiButton - root MuiButton - contained
// MuiButton - containedPrimary
// MuiButton - sizeMedium
// MuiButton - containedSizeMedium
// sc - hLBbgP bdWdKZ
// css - 1uq06w9 - MuiButtonBase - root - MuiButton - root
export function Theme({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

Theme.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.object,
};
