import { useMemo, useState } from "react";
import * as anchor from "@project-serum/anchor";
import Home from "./Home";
import { DEFAULT_TIMEOUT } from "./connection";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SlopeWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import PageHeader from "./components/PageHeader";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

import HomePage from "./pages/HomePage";
import TellingTheTale from "./pages/TellingTheTale";
import LegendaryFolkHeroes from "./pages/LegendaryFolkHeroes";
import MintResult from "./pages/MintResult";
import Traits from "./pages/Traits";
import MoreInfo from "./pages/MoreInfo";
import Minting from "./pages/Minting";

import { MintDataProvider } from "./context/MintData";
import { Theme } from "./context/Theme";
import { MetaplexProvider } from "./context/MetaplexContext";
import LostSkull from "./pages/LostSkull";
import YourJohnny from "./pages/YourJohnny";
// import CollectionDetails from "./components/CollectionDetails";

const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
  try {
    return new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID!);
  } catch (e) {
    console.log("Failed to construct CandyMachineId", e);
    return undefined;
  }
};

let error: string | undefined = undefined;

if (process.env.REACT_APP_SOLANA_NETWORK === undefined) {
  error =
    "Your REACT_APP_SOLANA_NETWORK value in the .env file doesn't look right! The options are devnet and mainnet-beta!";
} else if (process.env.REACT_APP_SOLANA_RPC_HOST === undefined) {
  error =
    "Your REACT_APP_SOLANA_RPC_HOST value in the .env file doesn't look right! Make sure you enter it in as a plain-text url (i.e., https://metaplex.devnet.rpcpool.com/)";
}

const candyMachineId = getCandyMachineId();
const network = (process.env.REACT_APP_SOLANA_NETWORK ??
  "devnet") as WalletAdapterNetwork;
const rpcHost =
  process.env.REACT_APP_SOLANA_RPC_HOST ?? anchor.web3.clusterApiUrl("devnet");
const connection = new anchor.web3.Connection(rpcHost);

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new SlopeWalletAdapter(),
      new SolletExtensionWalletAdapter({ network }),
    ],
    []
  );

  const [mobileMenu, setMobileMenu] = useState(false);
  const [currentPath, setCurrentPath] = useState("/");

  console.log(currentPath);

  return (
    <Router>
      <Theme>
        <MintDataProvider>
          <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
              <WalletDialogProvider>
                <MetaplexProvider>
                  <div className="site-container">
                    <div style={{ flex: 1 }}>
                      <PageHeader setMobileMenu={setMobileMenu} />
                      <NavBar
                        setCurrentPath={setCurrentPath}
                        setMobileMenu={setMobileMenu}
                        mobileMenu={mobileMenu}
                      />
                      <div className="mint-status-container">
                        <div className="mint-status">
                          <Home
                            candyMachineId={candyMachineId}
                            connection={connection}
                            txTimeout={DEFAULT_TIMEOUT}
                            rpcHost={rpcHost}
                            network={network}
                            error={error}
                            isNavbar={false}
                            isAfterMint={false}
                          />
                        </div>
                      </div>
                      <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route
                          path="/telling-the-tale"
                          element={<TellingTheTale />}
                        />
                        <Route
                          path="/legendary-folk-heroes"
                          element={<LegendaryFolkHeroes />}
                        />

                        <Route path="/traits" element={<Traits />} />
                        <Route path="/more-info" element={<MoreInfo />} />
                        <Route path="/minting" element={<Minting />} />
                        <Route
                          path="/mint-result"
                          element={<MintResult connection={connection} />}
                        />
                        <Route path="/lost-skull" element={<LostSkull />} />
                        <Route
                          path="/your-johnny"
                          element={<YourJohnny connection={connection} />}
                        />
                      </Routes>
                    </div>
                    <div>
                      <Footer />
                    </div>
                  </div>
                </MetaplexProvider>
              </WalletDialogProvider>
            </WalletProvider>
          </ConnectionProvider>
        </MintDataProvider>
      </Theme>
    </Router>
  );
};

export default App;
