import * as anchor from "@project-serum/anchor";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { DEFAULT_TIMEOUT } from "../connection";
import Home from "../Home";

function BottomMint() {
  const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
    try {
      return new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID!);
    } catch (e) {
      console.log("Failed to construct CandyMachineId", e);
      return undefined;
    }
  };

  const candyMachineId = getCandyMachineId();
  const network = (process.env.REACT_APP_SOLANA_NETWORK ??
    "devnet") as WalletAdapterNetwork;
  const rpcHost =
    process.env.REACT_APP_SOLANA_RPC_HOST ??
    anchor.web3.clusterApiUrl("devnet");
  const connection = new anchor.web3.Connection(rpcHost);

  let error: string | undefined = undefined;

  if (process.env.REACT_APP_SOLANA_NETWORK === undefined) {
    error =
      "Your REACT_APP_SOLANA_NETWORK value in the .env file doesn't look right! The options are devnet and mainnet-beta!";
  } else if (process.env.REACT_APP_SOLANA_RPC_HOST === undefined) {
    error =
      "Your REACT_APP_SOLANA_RPC_HOST value in the .env file doesn't look right! Make sure you enter it in as a plain-text url (i.e., https://metaplex.devnet.rpcpool.com/)";
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <p style={{ marginTop: "-1rem", padding: ".5rem", fontWeight: "600" }}>
        Tell another tale...
      </p>
      <Home
        candyMachineId={candyMachineId}
        connection={connection}
        txTimeout={DEFAULT_TIMEOUT}
        rpcHost={rpcHost}
        network={network}
        error={error}
        isNavbar={true}
        isAfterMint={true}
      />
    </div>
  );
}

export default BottomMint;
