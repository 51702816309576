import { HiOutlineDocumentText } from "react-icons/hi";
import { IoIosPeople } from "react-icons/io";
import {
  FaDiscord,
  FaRegListAlt,
  FaTiktok,
  FaTwitch,
  FaArrowRight,
} from "react-icons/fa";
import { RiMentalHealthLine } from "react-icons/ri";
import CollectionDetails from "../components/CollectionDetails";
import potHatImage from "../assets/badges/jfhBadgePotHat.png";
import skullImage from "../assets/icons/jfhBadgeSkull.png";

function MoreInfo() {

  return (
    <>
      <div className="page-background-solid">
        <div className="page-container">
          <CollectionDetails />
          <div className="page-title page-title-reverse">More Info</div>
        </div>
      </div>
      <div className="page-container">
        <div className="section-tab section-full-width">
          Johnny Folk Hero is a sibling collection of the <b>Skull of Tyger</b>{" "}
          collection.
          <p>
            This is also the final chapter of the <b>Lost Skulls of Tyger</b>{" "}
            NFT journey.
          </p>
        </div>
      </div>
      <div className="page-container">
      <div
          className="more-info-page-container"

      >
        <div className="page-card-container more-info-page-background bg-01">
          <div className="page-card-header header-alt">
            <HiOutlineDocumentText style={{ verticalAlign: "bottom" }} /> White
            Paper
          </div>
          <div className="page-card-content card-content-white">
            <p>We encourage everyone to do their own research.</p>
            <p>
              To help with this, we&apos;ve built an in-depth whitepaper to
              share the details on all of the Skull of Tyger projects, how they
              relate to each other and the blockchain specifics.
            </p>
            <p>
              <a
                href="https://whitepaper.skulloftyger.io/"
                target="_blank"
                rel="noreferrer"
              >
                <button className="page-button button-alt">
                  View White Paper
                </button>{" "}
              </a>
            </p>
          </div>
        </div>
        <div className="page-card-container more-info-page-background bg-02">
          <div className="page-card-header header-alt ">
            <IoIosPeople style={{ verticalAlign: "bottom" }} /> Community
            Creations
          </div>
          <div className="page-card-content card-content-white">
            <p>
              Play a mix of classic board games with the Skull of Tyger
              Community to determine the outcome of an original Johnny Folk Hero
              NFT.
            </p>
            <p>
              Live Art Community Creations give you the chance to choose a new
              trait that is drawn live or even win the art as a Solana NFT!
            </p>
              {/* <p>Schedule:</p>
            <span style={{ fontSize: "14px", fontStyle: "italic" }}>
              Subject to change, Join Discord for updates.
              </span> */}
              <div>
                <div>
                  <p><b>Chapter 1 has ended.</b></p>
                  <p><b>Stay tuned for updates on Chapter 2!</b></p>
              </div>
                {/* <div>
                  <p><b>Saturdays @ 10:00pm central</b></p>
              </div> */}
              <div
                style={{
                  display: "flex",
                  gap: ".2em",
                  fontSize: "1.5em",
                  flexDirection: "column",
                  marginTop: ".5em",
                }}
              >
                <a
                  href="https://www.twitch.tv/tylermcnair"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="btn-div">
                    <FaTwitch />
                    <div style={{ fontSize: ".7em" }}>twitch</div>
                  </div>
                </a>{" "}
                <a
                  href="https://www.tiktok.com/@tyler.r.mcnair"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="btn-div">
                    <FaTiktok />
                    <div style={{ fontSize: ".7em" }}>tiktok</div>
                  </div>
                </a>
              </div>
              </div>
          </div>
        </div>
        <div className="page-card-container more-info-page-background bg-03">
          <div className="page-card-header header-alt">
            <FaRegListAlt style={{ verticalAlign: "bottom" }} /> Skull of Tyger
            Pre-sale
          </div>
          <div className="page-card-content card-content-white">
            <p>
              Mint a Johnny Folk Hero and get added to the Skull of Tyger
              Pre-Sale List before it fills!
            </p>
            <p>
              Everyone on the Skull of Tyger Pre-sale list will get to mint a
              NFT from the{" "}
              <a
                href="https://whitepaper.skulloftyger.io/skull-of-tyger-tm/airdrops/skull-of-tyger-pre-sale-collection"
                target="_blank"
                rel="noreferrer"
              >
                commemorative launch collection
              </a>
              .
            </p>
          </div>
        </div>
        <div className="page-card-container more-info-page-background bg-04">
            <div className="page-card-header header-alt">Skull of Diamond <span className="sold-out">SOLD OUT</span></div>
          <div className="page-card-content card-content-white">
            <p>
              Mint a Johnny Folk Hero NFT with a <b>Pot Hat</b> and receive a
              token to mint the{" "}
              <a
                  href="https://diamond.skulloftyger.io/"
                target="_blank"
                rel="noreferrer"
              >
                Skull of Diamond
              </a>{" "}
              from the{" "}
              <a
                href="https://whitepaper.skulloftyger.io/skull-of-tyger-tm/sibling-collections/lost-skulls-of-tyger-tm"
                target="_blank"
                rel="noreferrer"
              >
                Lost Skulls of Tyger{" "}
              </a>
              event!
            </p>
            <p style={{ display: "flex", alignItems: "center" }}>
              <img src={potHatImage} style={{ width: "6rem" }} />
              <FaArrowRight size="2rem" />
              <img src={skullImage} style={{ width: "6rem" }} />
            </p>
              {/* <p>Have you found a Pot Hat? <br />Submit for your token to mint below.</p> */}

            <p>
                {/* <Link to={"/lost-skull"} className="page-button button-alt" >
                Submit for Token
              </Link> */}
                <div className="section-tab-header">
                  The Lost Skull of Diamond has been found!
                </div>
                <p>
                  Thanks to everyone who participated in the Lost Skulls Journey.
                  {/* <p>
                    For those that have all 11 Lost Gem Skulls, the Skull of Divinity is coming!
                  </p> */}

                  <p>Complete your collection of{" "}
                    <a href="https://opensea.io/collection/lost-skulls-of-tyger" target="_blank"
                      rel="noreferrer">The Lost Gem Skulls on OpenSea!</a></p>
                </p>

            </p> 
          </div>
        </div>
        <div className="page-card-container more-info-page-background bg-05">
          <div className="page-card-header header-alt">
            Holder Roles and Access
          </div>
          <div className="page-card-content card-content-white">
            <p>
              Join the{" "}
              <a
                href="https://discord.com/invite/7KPbcT4Ms2"
                target="_blank"
                rel="noreferrer"
              >
                Skull of Tyger Discord
              </a>{" "}
              and sync your wallet with collab.land to receive your holder
              roles.
            </p>
            <p>
              This will give you access to an exclusive channel of contests,
              giveaways and more.
            </p>
            <div
              style={{
                display: "flex",
                gap: ".2em",
                fontSize: "1.5em",
                flexDirection: "column",
                marginTop: ".5em",
              }}
            >
              <a
                href="https://discord.com/invite/7KPbcT4Ms2"
                target="_blank"
                rel="noreferrer"
              >
                <div className="btn-div">
                  <FaDiscord />
                  <div style={{ fontSize: ".7em" }}>discord</div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="page-card-container more-info-page-background bg-06">
          <div className="page-card-header header-alt">
            <RiMentalHealthLine style={{ verticalAlign: "bottom" }} /> Healing
            Wallet
          </div>
          <div className="page-card-content card-content-white">
            <p>
              20% of the mint will go to the{" "}
              <a
                href="https://whitepaper.skulloftyger.io/skull-of-tyger-tm/healing-wallet"
                target="_blank"
                rel="noreferrer"
              >
                Skull of Tyger Healing Wallet
              </a>
              .
            </p>
            <p>
              The Skull of Tyger Healing Wallet will fund self-growth events &
              opportunities for the community in an effort to impact mental
              health, happiness & improve emotional quality of life.
            </p>
          </div>
        </div>
        </div>
      </div>
    </>
  );
}

export default MoreInfo;
