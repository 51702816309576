
import CollectionDetails from "../components/CollectionDetails";


function LostSkull() {


  return (
    <>
      <div className="page-background-solid">
        <div className="page-container">
          <CollectionDetails />
          <div className="page-title page-title-reverse">Skull of Diamond</div>
        </div>
      </div>
      <div className="page-container">
        <div className="section-tab section-full-width">
          <div className="section-tab-header">
            The Lost Skull of Diamond has been found!
          </div>
          <p>
            Thanks to everyone who participated in the Lost Skulls Journey.
            <p>
              For those that have all 11 Lost Gem Skulls, the Skull of Divinity is coming!
            </p>

            <p>If you don&apos;t have all the skulls, complete your collection of{" "}
              <a href="https://opensea.io/collection/lost-skulls-of-tyger" target="_blank"
                rel="noreferrer">The Lost Gem Skulls on OpenSea!</a></p>
          </p>
        </div>
      </div>
      <div className="page-container">
        <div style={{ marginTop: "1em" }}>
          somethin
        </div>
      </div>

    </>
  );
}

export default LostSkull;
