import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Zoom,
  Autoplay,
  EffectCards,
  Thumbs,
} from "swiper";
import "swiper/swiper-bundle.css";

import PropTypes from "prop-types";
import { useState } from "react";

function Slider({ imageSet, slidesPerView, thumbRows, spacing }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <>
      <Swiper
        modules={[
          Navigation,
          Pagination,
          Scrollbar,
          A11y,
          Zoom,
          Autoplay,
          EffectCards,
          Thumbs,
        ]}
        // slidesPerView={6}
        // spaceBetween={20}
        navigation
        // effect="cards"
        thumbs={{ swiper: thumbsSwiper }}
        // cardsEffect={{
        //   slideShadows: false,
        //   rotate: false,
        //   perSlideOffset: 5,
        // }}
        autoplay={{ delay: 2000, pauseOnMouseEnter: true }}
        zoom={{ maxRatio: 5, toggle: true }}
        pagination={{ clickable: true }}
        // breakpoints={{
        //   425: {
        //     cardsEffect: { perSlideOffset: 6 },
        //   },
        //   768: {
        //     cardsEffect: { perSlideOffset: 12 },
        //   },
        //   1020: {
        //     cardsEffect: { perSlideOffset: 16 },
        //   },
        // }}
      >
        {imageSet.map((heroImage, index) => (

          <SwiperSlide key={index}>
            <div className="swiper-zoom-container">
              <img
                src={heroImage.url}
                alt={heroImage.name}
                className={imageSet[0].name === "amythyst" ? `swiper-image-large` : `swiper-image`}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Thumbnail Slider */}
      <div
        style={{
          margin: "auto",
          width: "auto",
          marginTop: "1rem",

        }}
      >
        <Swiper
          modules={[Thumbs]}
          watchSlidesProgress
          onSwiper={setThumbsSwiper}
          slidesPerView={slidesPerView}
          spaceBetween={spacing}
          grid={{ rows: thumbRows, fill: "row" }}
        >
          {imageSet.map((heroImage, index) => (
            <SwiperSlide key={index}>
              <img
                src={heroImage.url}
                alt={heroImage.name}
                className="swiper-thumb-image"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}

Slider.propTypes = {
  imageSet: PropTypes.array,
  slidesPerView: PropTypes.number,
  thumbRows: PropTypes.number,
  spacing: PropTypes.number,
};

export default Slider;
