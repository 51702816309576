import SocialBlock from "./SocialBlock";
import PropTypes from "prop-types";
function CollectionDetails({ isHomePage }) {
  return (
    <>
      <div
        className={`site-details-socials  ${isHomePage === true ? "" : `page-title-reverse`
          }`}
        style={{ fontWeight: "bolder" }}
      >
        <div className="site-details-title">Collection Details</div>
        <div>
          <SocialBlock />
        </div>
      </div>
      <div className="site-mint-details-container">
        <div
          className={`site-mint-details-pair ${isHomePage === true ? "" : `pair-solid`
            } `}
        >
          <div className="site-mint-details-title">Price: </div>
          <div className="site-mint-detail">0.25 SOL </div>
        </div>
        <div
          className={`site-mint-details-pair ${isHomePage === true ? "" : `pair-solid`
            } `}
        >
          <div className="site-mint-details-title">Mint Date: </div>
          <div className="site-mint-detail">1.20.2023 </div>
        </div>
        <div
          className={`site-mint-details-pair ${isHomePage === true ? "" : `pair-solid`
            } `}
        >
          <div className="site-mint-details-title">Editions: </div>
          <div className="site-mint-detail"> 2100*</div>
        </div>
      </div>
    </>
  );
}
CollectionDetails.propTypes = {
  isHomePage: PropTypes.bool,
};

export default CollectionDetails;
