
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import skullOfDiamondBadge from "../assets/badges/jfhBadgeSkullOfDiamond.png"
function NftBadge({ badge, id, value, nftName }) {

  return (
    <>

      {value === "Pot" && (<>
        <div key={id} className="nft-badge-container">
          <img className="nft-badge-img" src={badge.url} alt={`${badge.imgName} Hat`} />
          <span className="nft-badge-text">
            {/* {potHat} */}
            Pot Hat
          </span>
        </div>
        <div key={id * 90} className="nft-badge-container">
          <Link to={`/lost-skull`} state={{ nftName: nftName }} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

            <img className="nft-badge-img" src={skullOfDiamondBadge} alt="Skull of Diamond" />
            <div className="nft-badge-text">
              Skull of Diamond
            </div>
          </Link>
        </div>
      </>)}
      {badge.imgName === "Bird on Shoulder" && (
        <div key={id} className="nft-badge-container">
          <img className="nft-badge-img" src={badge.url} alt={badge.imgName} />
          <span className="nft-badge-text">
            {/* {value} */}
            Bird on Shoulder
          </span>
        </div>
      )}
      {badge.imgName === "Dog" && (
        <div key={id} className="nft-badge-container">
          <img className="nft-badge-img" src={badge.url} alt={badge.imgName} />
          <span className="nft-badge-text">
            {/* {value} */}
            Dog
          </span>
        </div>
      )}
      {badge.trait_type === "Legendary Folk Hero" && value === badge.imgName && (
        <div key={id} className="nft-badge-container">
          <img className="nft-badge-img" src={badge.url} alt={value} />
          <span className="nft-badge-text">
            {badge.imgName}
          </span>
        </div>
      )}

    </>
  );
}
export default NftBadge;
NftBadge.propTypes = {
  badge: PropTypes.object,
  value: PropTypes.string,
  potHat: PropTypes.string,
  nftName: PropTypes.string,
  id: PropTypes.number,
};
