import CollectionDetails from "../components/CollectionDetails";
import TraitCard from "../components/TraitCard";

function Traits() {
  return (
    <>
      <div className="page-background-solid">
        <div className="page-container">
          <CollectionDetails />
          <div className="page-title page-title-reverse">Traits</div>
        </div>
      </div>
      <div className="page-container">
        <div className="section-tab section-full-width">
          <div className="section-tab-header">There are over 50 trait locations with multiple variables.</div>
          <p>
            The traits have a variety of rarities and are often dependent on
            the outcomes of another trait.
          </p>
          <p >
            The list below is the <b>OFFICIAL </b>outcome of the 2100 generated NFTs. Actual
            rarity will depend on the overall mint count and the addition of
            any Community Creations & Special Editions.
          </p>
        </div>
      </div>
      <div style={{ marginTop: "1em" }}>
        <TraitCard />
      </div>
    </>
  );
}

export default Traits;
