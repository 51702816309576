const traitBadges = [
  {
    imgName: "Bird on Shoulder",
    trait_type: "Bird on Shoulder",
    url: require("./jfhBadgeBird.png"),
  },
  {
    imgName: "Dog",
    trait_type: "Dog",
    url: require("./jfhBadgeDog.png"),
  },
  {
    imgName: "Pot",
    trait_type: "Hat Style",
    url: require("./jfhBadgePotHat.png"),
  },
  {
    imgName: "Astronaut",
    trait_type: "Legendary Folk Hero",
    url: require("./jfhBadgeAstronaut.png"),
  },
  {
    imgName: "Hazmat",
    trait_type: "Legendary Folk Hero",
    url: require("./jfhBadgeHazmat.png"),
  },
  {
    imgName: "Scuba Diver",
    trait_type: "Legendary Folk Hero",
    url: require("./jfhBadgeScuba.png"),
  },
  {
    imgName: "Skull of Tyger",
    trait_type: "Legendary Folk Hero",
    url: require("./jfhBadgeSkullOfTyger.png"),
  },
  {
    imgName: "Surfer",
    trait_type: "Legendary Folk Hero",
    url: require("./jfhBadgeSurfer.png"),
  },
];
export default traitBadges;
