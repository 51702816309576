import { useEffect, useState } from "react";
import {
  FaGlobe,
  FaInstagram,
  FaTwitter,
  FaTwitch,
  FaTiktok,
  FaDiscord,
} from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { ReactComponent as OpenSeaLogo } from "../assets/icons/openSea.svg";
// import { AiFillInstagram } from "react-icons/ai";

function SocialBlock() {
  const [isHomePage, setHomePage] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/mint-result") {
      setHomePage(true)
    } else {
      setHomePage(false)
    }

  }, [location])

  return (
    <div className="social-group-container">
      <div>
        <a
          href="https://www.tiktok.com/@tyler.r.mcnair"
          target="_blank"
          rel="noreferrer"
        >
          <FaTiktok className={isHomePage ? "social-link" : "social-link-rev"} />
        </a>
      </div>
      <div>
        <a
          href="https://opensea.io/collection/johnny-folk-hero"
          target="_blank"
          rel="noreferrer"
          className="social-link"
        >
          <OpenSeaLogo width="16px" height="16px" className={isHomePage ? "social-link" : "social-link-rev"} />
        </a>
      </div>
      <div>
        <a
          href="https://www.tylermcnair.com/johnnyfolkhero"
          target="_blank"
          rel="noreferrer"
        >
          <FaGlobe className={isHomePage ? "social-link" : "social-link-rev"} />
        </a>
      </div>
      <div>
        <a
          href="https://www.instagram.com/skulloftyger/"
          target="_blank"
          rel="noreferrer"
        >
          <FaInstagram className={isHomePage ? "social-link" : "social-link-rev"} />
        </a>
      </div>
      <div>
        <a
          href="https://twitter.com/SkullofTyger"
          target="_blank"
          rel="noreferrer"
        >
          <FaTwitter className={isHomePage ? "social-link" : "social-link-rev"} />
        </a>
      </div>
      <div>
        <a
          href="https://www.twitch.tv/tylermcnair"
          target="_blank"
          rel="noreferrer"
        >
          <FaTwitch className={isHomePage ? "social-link" : "social-link-rev"} />
        </a>
      </div>
      <div>

      </div>
        <a
          href="https://discord.com/invite/7KPbcT4Ms2"
          target="_blank"
          rel="noreferrer"
        >
        <FaDiscord className={isHomePage ? "social-link" : "social-link-rev"} />
      </a>
    </div>
  );
}

export default SocialBlock;
