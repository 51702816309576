import traitStats from "../assets/rarityFormat.json";
import { FaPercentage } from "react-icons/fa";

function TraitCard() {
  return (
    <>
      <div className="page-container">

        <div className="trait-page-card-container" >
          {Object.keys(traitStats).map((traitName, id1) => (
            <div key={id1} className="trait-page-card">
              <div className="trait-page-card-title">{traitName}</div>
              <div className="trait-page-card-data trait-page-card-header">
                <div className="trait-card-data-row">
                  {traitName === "Attribute Count" ? `Attributes` : `Value`}
                </div>
                <div className="trait-card-data-row" >Count</div>
                <div className="trait-card-data-row" >
                  <FaPercentage />
                </div>
              </div>
              {Object.values(traitStats[traitName]).map((data, id2) => (
                <div key={id2} className="trait-page-card-data">
                  <div className="trait-card-data-row">{data.Attribute}</div>
                  <div className="trait-card-data-row" >{data.Count}</div>
                  <div className="trait-card-data-row">
                    {Math.round((Number(data.Percentage) + Number.EPSILON) * 100) / 100}
                    %
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default TraitCard;
