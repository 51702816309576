import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
type Props = {
  setCurrentPath: React.Dispatch<string>;
  setMobileMenu: React.Dispatch<boolean>;
  mobileMenu: Boolean;
};
function Buttons({ setCurrentPath, setMobileMenu, mobileMenu }: Props) {
  const showMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };
  const location = useLocation();

  const navigate = useNavigate();
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);
  // console.log(location.pathname);

  return (
    <>
      <div className={mobileMenu === false ? `btn-group` : `btn-group active`}>
        <div className="btn-bar">
          <div
            className="btn-text"
            onClick={() => {
              navigate("/telling-the-tale");
              setMobileMenu(false);
            }}
          >
            Telling the Tale
          </div>
        </div>
        <div className="btn-bar">
          <div
            className="btn-text"
            onClick={() => {
              navigate("/legendary-folk-heroes");
              setMobileMenu(false);
            }}
          >
            Legendary Folk Heroes
          </div>
        </div>

        <div className="btn-bar">
          <div
            className="btn-text"
            onClick={() => {
              navigate("/traits");
              setMobileMenu(false);
            }}
          >
            Traits
          </div>
        </div>
        <div className="btn-bar">
          <div
            className="btn-text"
            onClick={() => {
              navigate("/more-info");
              setMobileMenu(false);
            }}
          >
            More Info
          </div>
        </div>
        <div className="btn-bar">
          <div
            className="btn-text"
            onClick={() => {
              navigate("/your-johnny");
              setMobileMenu(false);
            }}
          >
            Viewer
          </div>
        </div>
      </div>
      <div
        className={mobileMenu === false ? `hamburger` : `hamburger active`}
        onClick={showMobileMenu}
      >
        <span className="hamburger-bar" />
        <span className="hamburger-bar" />
        <span className="hamburger-bar" />
      </div>
    </>
  );
}

export default Buttons;
