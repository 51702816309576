import { useNavigate } from "react-router-dom";
type Props = {
  setMobileMenu: React.Dispatch<boolean>;
};
function PageHeader({ setMobileMenu }: Props) {
  const navigate = useNavigate();
  return (
    <>
      <div className="site-header-background">
        <div
          className="site-title-background"
          onClick={() => {
            navigate("/");
            setMobileMenu(false);
          }}
        >
          <div className="site-header-text-container">
            <div className="site-header-hero-text">Johnny Folk Hero</div>
            <div className="site-header-divider"></div>
            <div className="site-header-sub-text">
              A Solana Collection Spreading Love, Peace, Happiness & Positivity
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PageHeader;
