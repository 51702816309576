function Footer() {
  return (
    <div className="site-footer">
      <div>NFT Art4Life LLC.</div>
      <div>|</div>
      <div>© tyler.r.mcnair 2024</div>
    </div>
  );
}

export default Footer;
