import { Metaplex } from "@metaplex-foundation/js";
import { useWallet } from "@solana/wallet-adapter-react";
import Lottie from "react-lottie-player";
import animationData from "../assets/icons/johnnyWalker_lottie.json";
import { useEffect, useState } from "react";
import CollectionDetails from "../components/CollectionDetails";
import traitBadges from "../assets/badges";
import NftBadge from "../components/NftBadge";
import explorerIcon from "../assets/icons/explorer.png";
import solanaFmIcon from "../assets/icons/solanaFM.png";
import solscanIcon from "../assets//icons/solscan.png";
import BottomMint from "../components/BottomMint";
import PropTypes from "prop-types";
// import { useMintData } from "../context/MintData";

// import { PublicKey } from "@solana/web3.js";

function YourJohnny({ connection }) {
  // const [dogCount, setDogCount] = useState(0)
  const mx = Metaplex.make(connection);
  const [nftList, setNftList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalTraits, setTotalTraits] = useState(0);
  const [totalNfts, setTotalNfts] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(null);
  const perPage = 1;
  // const { mintAddress } = useMintData();
  // const mintAddress = new PublicKey("GFr6A3mtwbyETm3zvSCUSpTyfWXupr2poboFEMRTpPTL")
  // const humanMintAddress = mintAddress?.toBase58();
  const wallet = useWallet();
  // console.log(humanMintAddress)
  console.log(currentView)

  let foundSpecialTraits = false;
  let potHatColor = "";
  useEffect(() => {

    const getNfts = async () => {

      try {
        if (!wallet.connected) {
          throw ("no wallet connected");
        }

        setLoading(true);
        setCurrentView(null);

        const list = await mx
          .nfts()
          .findAllByOwner({ owner: wallet.publicKey });
        const allJohnny = list.filter((allNfts) =>
          allNfts.name.includes("Johnny Folk Hero")
        );
        setNftList(allJohnny);
        setTotalNfts(allJohnny.length)
        setCurrentPage(1);

      } catch (e) {
        console.log(e);
      }
    };


    getNfts();
  }, [wallet]);

  useEffect(() => {
    if (!nftList) {
      return;
    }
    const execute = async () => {
      const startIndex = (currentPage - 1) * perPage;
      const endIndex = currentPage * perPage;
      const nfts = await loadData(startIndex, endIndex);

      setCurrentView(nfts);
      setTotalTraits(nfts[0].json.attributes.length);
      setLoading(false);
    };
    execute();

  }, [currentPage, nftList]);

  if (currentView && currentView.length > 0) {
    currentView[0].json.attributes.map((trait) => {
      traitBadges.map((badge) => {
        if (badge.imgName === trait.trait_type) {
          foundSpecialTraits = true;
        }
        if (badge.imgName === trait.value) {
          foundSpecialTraits = true;
        }
        if (trait.trait_type === "Hat Color") {
          potHatColor = trait.value;
        }
      });
    });
  }

  console.log("nftList: ", currentView);


  const loadData = async (startIndex, endIndex) => {

    const nftsToLoad = nftList.filter(
      (_, index) => index >= startIndex && index < endIndex
    );

    const promises = nftsToLoad.map((metadata) => mx.nfts().load({ metadata }));
    return Promise.all(promises);
  };

  const changeCurrentPage = (operation) => {
    setLoading(true);
    if (operation === "next") {
      setCurrentPage((prevValue) => prevValue + 1);
    } else {
      setCurrentPage((prevValue) => (prevValue > 1 ? prevValue - 1 : 1));
    }
  };

  return (
    <>
      <div className="page-background">
        <div className="page-container">
          <CollectionDetails isHomePage={true} />
          <div className="page-title">Your Johnny Folk Heroes</div>
          <div className="load-johnny-container">
            {!nftList && (<p style={{ textAlign: "center", marginBottom: "1em" }}>Connect your wallet containing <b>Johnny Folk Hero</b> NFTs to view</p>)}
            {totalNfts === 0 && (<p style={{ textAlign: "center", marginBottom: "1em" }}>Connect your wallet containing <b>Johnny Folk Hero</b> NFTs to view</p>)}
            {currentView && totalNfts > 0 && (<>
              <div style={{ textAlign: "center", marginBottom: ".5rem" }}>Detected <b>{totalNfts}</b> Johnny Folk Heroes</div>
              <div className="viewer-button-contain">
                <button
                  disabled={currentPage === 1}
                  onClick={() => changeCurrentPage("prev")}
                  className="page-button button-alt"
                >
                  Prev
                </button>
                <button
                  disabled={nftList && nftList.length / perPage === currentPage}
                  onClick={() => changeCurrentPage("next")}
                  className="page-button button-alt"
                >
                  Next
                </button>
              </div>
            </>)}

            {loading && totalNfts > 0 ? (
              <>
                <div style={{ display: "flex", alignItems: "center", flexDirection: "column", marginBottom: "1.2rem" }}>
                  <div className="page-title" style={{ fontSize: "1rem" }} >
                    Retrieving...
                  </div>
                  <Lottie

                    loop
                    animationData={animationData}
                    play
                    style={{ width: "200px", height: "200px" }}

                  /></div>
              </>
            ) : (
                currentView &&
                currentView.map((nft, index) => (
                  <div key={index}>
                    <div
                      className="minted-generated-combo"
                      style={{ minHeight: "40rem", marginBottom: "1rem" }}
                    >
                      <img
                        src={nft?.json.image}
                        alt="NFT Image"
                        className="nft-image"
                      />
                      <div className="nft-data-container">
                        <div style={{ margin: "1rem" }}>
                          <div className="page-card-container">
                            <div className="page-card-header">
                              {nft?.name}

                            </div>

                            <div className="page-card-content">
                              <p>{nft?.json.description}</p>
                            </div>
                          </div>
                          <div className="section-tab add-shadow">
                            <div>
                              This Johnny Folk Hero has <b>{totalTraits}</b>{" "}
                              traits!
                            </div>
                          </div>
                          {foundSpecialTraits === true && (
                            <>
                              <p className="nft-badge-header">Badges acquired:</p>
                              <div className="nft-badge-group">
                                {nft?.json.attributes.map((specialTrait) =>
                                  traitBadges.map((badge, id) => {
                                    if (
                                      specialTrait.trait_type === badge.trait_type
                                    ) {
                                      return (
                                        <NftBadge
                                          key={id}
                                          badge={badge}
                                          id={id}
                                          value={specialTrait.value}
                                          potHat={potHatColor}
                                          nftName={nft?.name}
                                        />
                                      );
                                    }
                                  })
                                )}
                              </div>
                            </>
                          )}

                          <div className="mint-explorer-container">
                            <div className="mint-explorer-view">View NFT:</div>
                            <a
                              className="mint-explorer-group"
                              href={`https://solscan.io/token/${nft?.address.toBase58()}`}
                            >
                              <div>
                                <img
                                  className="mint-explorer-icon"
                                  src={solscanIcon}
                                  alt="solscan"
                                />
                              </div>
                              <div className="mint-explorer-text">Solscan</div>
                            </a>
                            <a
                              className="mint-explorer-group"
                              href={`https://solana.fm/address/${nft?.address.toBase58()}`}
                            >
                              <div>
                                <img
                                  className="mint-explorer-icon"
                                  src={solanaFmIcon}
                                  alt="solscan"
                                />
                              </div>
                              <div className="mint-explorer-text">Solana FM</div>
                            </a>
                            <a
                              className="mint-explorer-group"
                              href={`https://explorer.solana.com/address/${nft?.address.toBase58()}`}
                            >
                              <div>
                                <img
                                  className="mint-explorer-icon"
                                  src={explorerIcon}
                                  alt="solscan"
                                />
                              </div>
                              <div className="mint-explorer-text">Explorer</div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mint-bottom">
                      <BottomMint />
                    </div>
                  </div>
                ))
            )}
          </div>{" "}
        </div>
      </div>
    </>
  );
}
YourJohnny.propTypes = {
  connection: PropTypes.any,
};

export default YourJohnny;
