import React, { createContext, useContext, useMemo } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { Metaplex, walletAdapterIdentity } from "@metaplex-foundation/js";

type Props = {
  children: React.ReactNode;
};

const DEFAULT_CONTEXT = {
  metaplex: null,
};

export const MetaplexContext = createContext(DEFAULT_CONTEXT);

export const MetaplexProvider = ({ children }: Props) => {
  const { connection } = useConnection();
  const wallet = useWallet();

  const metaplex = useMemo<any>(
    () => Metaplex.make(connection).use(walletAdapterIdentity(wallet)),
    [connection, wallet]
  );

  return (
    <MetaplexContext.Provider value={{ metaplex }}>
      {children}
    </MetaplexContext.Provider>
  );
};

// useMetaplex() is responsible for creating and exposing a new Metaplex Context
export function useMetaplex() {
  return useContext(MetaplexContext);
}
