const LegendaryHeroes_Space = [
  {
    name: "moon1",
    url: require("./jfhLegendary_001.jpg"),
  },
  {
    name: "moon2",
    url: require("./jfhLegendary_002.jpg"),
  },
  { name: "mars1", url: require("./jfhLegendary_007.jpg") },
  { name: "mars2", url: require("./jfhLegendary_008.jpg") },
];
export default LegendaryHeroes_Space;
