import heroImage from "../assets/jfhHomeHero01_yellow.png";
import { GiQuillInk } from "react-icons/gi";
import CollectionDetails from "../components/CollectionDetails";
import { useNavigate } from "react-router-dom";

function HomePage() {
  const navigate = useNavigate();
  return (
    <div className="page-background">
      <div className="page-container">
        <CollectionDetails isHomePage={true} />
        <div className="home-page-motion-group">
          <div>
            <div className="home-page-text-1">In a time of need...</div>
            <div className="home-page-text-2">A Hero Emerges.</div>
          </div>
          <img
            className="home-page-johnny-image"
            src={heroImage}
            alt="Johnny Hero"
          />
        </div>
        <div className="page-card-container home-page-card" style={{ marginBottom: "2rem" }}>
          <div className="page-card-header">
            Tell Your Tale <GiQuillInk style={{ verticalAlign: "middle" }} />
          </div>
          <div className="page-card-content">
            <p>
              Every Johnny Folk Hero NFT will tell a unique story. A story
              written by art and metadata making every outcome tell a tale true
              to <span style={{ fontStyle: "italic" }}>your </span>
              Johnny Folk Hero!
            </p>
            <p>

              <button
                className="page-button"
                onClick={() => {
                  navigate("/telling-the-tale");
                }}
              >
                What will your story be?
              </button>

            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
