const SkullOfTygerHero = [
  {
    name: "amythyst",
    url: require("./jfhSkullOfTyger_001.jpg"),
  },
  { name: "aquamarine", url: require("./jfhSkullOfTyger_002.jpg") },
  { name: "bone", url: require("./jfhSkullOfTyger_003.jpg") },
  { name: "citrine", url: require("./jfhSkullOfTyger_004.jpg") },
  { name: "diamond", url: require("./jfhSkullOfTyger_005.jpg") },
  { name: "emerald", url: require("./jfhSkullOfTyger_006.jpg") },
  { name: "peridot", url: require("./jfhSkullOfTyger_007.jpg") },
  {
    name: "pink tourmaline",
    url: require("./jfhSkullOfTyger_008.jpg"),
  },
  { name: "ruby", url: require("./jfhSkullOfTyger_009.jpg") },
  { name: "sapphire", url: require("./jfhSkullOfTyger_010.jpg") },
  { name: "yellow diamond", url: require("./jfhSkullOfTyger_011.jpg") },
];
export default SkullOfTygerHero;
